import React, { useState, useEffect, useRef } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { request, toBase64 } from '../../lib/api'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import ProgressBar from 'react-bootstrap/ProgressBar'

const ProductUpdate = ({ location, id }) => {

  const [product, setProduct] = useState({
    _id: null,
    name: "",
    isFavorite: false,
    is_active: false,
    desc: ""
  })
  let productId = ''
  const formRef = useRef(null);
  const [ featureTypes, setfeatureTypes ] = useState([])
  const [ features, setFeatures ] = useState([])
  const [ categories, setCategories ] = useState(null)
  const [ uploadPerc /*,setUploadPerc */] = useState(-1)
  const [ uploadFeatPerc /*, setUploadFeatPerc */ ] = useState(-1)
  const [ prodFile, setProdFile ] = useState(null)
  const [ featFile, setFeatFile ] = useState(null)

  useEffect(()=>{
    const urlParams = new URLSearchParams(location.search);
    productId = urlParams.get('id');

    if (!product._id) {
      request("GET", '/wwd/products').then((res)=>{
        const prod = res.products.filter((p)=>(p._id === productId))
        if (prod.length>0) {
          setProduct(prod[0])
        }
      })
    }
    if (features.length === 0) {
      request("GET", `/wwd/product/${productId}/features`).then((res)=>{
        if (res.features.length > 0) {
          setFeatures(res.features)
        }
      })
    }
    if (featureTypes.length === 0) {
      request("GET", `/wwd/featuretypes`).then((res)=>{
        if (res.featureTypes.length > 0) {
          setfeatureTypes(res.featureTypes)
        }
      })
    }
    if (categories === null) {
      getCategories()
    }
  })

  const getProductFeatures = () => {
    request("GET", `/wwd/product/${productId}/features`).then((res)=>{
      setFeatures(res.features)
    })
  }

  const getCategories = ()=>{
    request("GET", '/wwd/categories').then((res)=>{
      setCategories(res.results)
    })
  }

  const onProdChange = (e) => {
    const { name, value } = e.target
    setProduct({ 
      ...product,
      [name]: value
    })
  }

  const [feature, setFeature] = useState({})
  const onFeatChange = (e) => {
    const { name, value } = e.target
    setFeature({ 
      ...feature,
      [name]: value
    })
  }

  const updateProduct = async (e) => {
    e.preventDefault()
    if (prodFile && prodFile !== null && prodFile !== undefined) {
      const { name, type, size } = prodFile
      const base64 = await toBase64(prodFile)
      product.file = { name, type, size, base64 }
    }
    request("POST", '/wwd/products/update', product).then((res)=>{
      if (prodFile && res.filename) {
        setProdFile(null)
        setProduct({
          ...product,
          src: res.filename
        })
      }
    })
  }

  const deleteFeature = (feature) => {
    request('POST', `/wwd/features/delete`, { id: feature._id })
    .then(results => {
      getProductFeatures();
    }).catch(err => {
      alert('There was an error deleting this feature. Please try again later.')
    })
  }

  const addFeature = async (e) => {
    e.preventDefault()
    if (featFile && featFile !== null && featFile !== undefined) {
      const { name, type, size } = featFile
      const base64 = await toBase64(featFile)
      feature.file = { name, type, size, base64 }
    }
    feature.product_id = product._id
    request('POST', `/wwd/features/new`, feature).then((res)=>{
      setFeature({})
      setFeatFile(null)
      setFeatures([ ...features, res.feature ])
      formRef.current.reset()
    }).catch( err => console.log(err) )
  }

  const favoriteToggler = (e) => {
    request('POST', `/wwd/products/favorite`, {id: product._id, isFavorite: !product.isFavorite })
      .then( res => console.log(res))
      .catch( err => console.log(err) )
  }

  const formatImg = (url) => {
    if (url === null || url === undefined || url === '') { return null }
    if ( url.indexOf('http') > -1 ) { return url }
    if ( url.split('/').length === 1 ) { url = 'assets/img/' + url }
    return `https://wastewaterdepot.com/${url}`
  }

  return (
    <Layout style={{ marginTop: '10%' }}>
      <SEO title="Product" />
      <div className="container-fluid">
        <div className="ProductInfo">
          <Container>
            <Row>
              <Col lg={12}>
                {
                    product.isFavorite && <input type="checkbox" name="isFavorite" defaultChecked={product.isFavorite}
                    onChange={e => favoriteToggler(e)} />
                }
                {
                    !product.isFavorite && <input type="checkbox" name="isFavorite" defaultChecked={product.isFavorite}
                    onChange={e => favoriteToggler(e)} />
                }
                Favorite Product
                <div className="text-muted">
                  Only 16 favorite products will be displayed on the home page
                </div>
                <h2>{product.name}</h2>
                {
                  formatImg(product.src) && <img src={formatImg(product.src)} alt="" />
                }
              </Col>

              <Col lg={12}>
                <Form className="form-shadow" onSubmit={(e) => updateProduct(e)}>
                <h3>Update Product</h3>
                  Product Rank: 
                  <input name="rank" defaultValue={product.rank} type="text" onChange={e => onProdChange(e)} />
                  <div className="text-muted">This value dictates the order products are displayed on the homepage only if favorited</div>
                  <br/><br/>
                  <h5>Product Name</h5>
                  <input type="text" defaultValue={product.name} onChange={e => onProdChange(e)} name="name" />
                  <br/><br/>
                  <h5>Product Description</h5>
                  <textarea name="desc" defaultValue={product.desc} onChange={(e) => onProdChange(e)} rows="4" cols="75"></textarea>
                  <br/><br/>
                  {
                    product.category !== undefined && (
                      <>
                        <h5>Product Category</h5>
                          <Form.Group controlId="desc">
                            <Form.Control as="select" name="category" placeholder="Product Category" 
                              onChange={(e)=>onProdChange(e)} defaultValue={product.category} >
                              <option value={''}>Select Category</option>
                              {
                                categories !== null && categories.map((cat)=>(
                                  <option key={cat._id} value={cat._id}>{cat.title}</option>
                                ))
                              }
                            </Form.Control>
                          </Form.Group>
                      </>
                    )
                  }
                  
                  <h5>Update image</h5>
                  <div className="custom-file" style={{maxWidth: '300px'}}>
                    <input name="productsrc" type="file" onChange={(e) => setProdFile(e.target.files[0])} 
                      className="custom-file-input" id="productFile" />
                    <label className="custom-file-label" htmlFor="productFile">
                      { prodFile ? prodFile.name : "Upload File" }
                    </label>
                    {
                      uploadPerc >= 0 &&
                      (
                        <div style={{width: '500px'}}>
                          <ProgressBar striped variant="info" now={uploadPerc} 
                            label={`${uploadPerc}%`}/>
                        </div>
                      )
                    }
                  </div>
                  <br/>
                  <br/>
                  <input className="btn btn-primary" type="submit" value="Submit" />
                </Form>
              </Col>
            </Row>

            <Row>
              <Col className="product-info" lg={12}>
                <h3>Product Features and Info</h3>
              </Col>

              <Col lg={12}>
                <form className="form-shadow" onSubmit={(e) => addFeature(e)} ref={formRef} >
                  <h5>Add A Feature</h5>
                  <p style={{color: 'red'}}>
                    Only upload a feature with either an image or text.
                  </p>
                  <textarea name="text" onChange={ (e) => onFeatChange(e) } rows="3" cols="50" 
                    placeholder="Enter product information, choose a category, and create." />
                  <br/>
                  <div className="custom-file" style={{maxWidth: '300px'}}>
                    <input name="featuresrc" type="file" onChange={(e) => setFeatFile(e.target.files[0])} 
                      className="custom-file-input" id="featFile" />
                    <label className="custom-file-label" htmlFor="featFile">
                      { featFile ? featFile.name : "Upload File" }
                    </label>
                    {
                      uploadFeatPerc >= 0 &&
                      (
                        <div style={{width: '500px'}}>
                          <ProgressBar striped variant="info" now={uploadFeatPerc} 
                            label={`${uploadFeatPerc}%`}/>
                        </div>
                      )
                    }
                  </div>
                  <br/>
                  <br/>
                  <div >
                    <select className="form-control col-6" value={feature.feature_type_id} 
                      name="feature_type_id" onChange={ (e) => onFeatChange(e) } 
                      defaultValue={featureTypes[0]} >
                      {
                        featureTypes
                        ? featureTypes.map((type)=>{
                            return <option key={type._id + '2'} value={type._id}>{type.type}</option>
                          })
                        : null
                      }
                    </select>
                  </div>
                  <br/>
                  <input className="btn btn-primary" type="submit" value="Submit" />
                </form>
              </Col>

              {
                (features && features.length > 0)
                ? features.map((feature)=>{
                    return (feature.src && feature.src !== '' && feature.src !== null) 
                    ? <div className="col-8 product-info" key={feature._id}>
                        <p>Info Type: {
                          featureTypes.filter((type) => type._id === feature.feature_type_id).map(
                            (filteredType)=>(filteredType.type)
                          )}
                        </p>
                        <img src={`${formatImg(feature.src)}`} key={feature._id} width="450px" alt='Feature' /> 
                        <Button onClick={()=>deleteFeature(feature)}>Delete</Button>  
                      </div> 
                    : <div className="col-5 product-info" key={feature._id + '1'}>
                        <p>Info Type: { 
                         featureTypes.filter((type) => type._id === feature.feature_type_id).map(
                          (filteredType)=>(filteredType.type)
                          )}
                        </p>
                        <p key={feature._id}>{feature.text}</p>
                        <Button onClick={()=>deleteFeature(feature)}>Delete</Button> 
                      </div>
                        })
                : null
              }
            </Row>
          </Container>
      </div>
      </div>
    </Layout>
  )
}


export default ProductUpdate
